import React from 'react';

import logoImg from '../../assets/logo.svg';

import { Container, Content, Background, AnimationContainer } from './styles';

const SignIn: React.FC = () => {
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div>
            <img src={logoImg} alt="Vispunt" />
            <h1>Vispunt.nl</h1>
            <p>Order by WhatsApp</p>
            <p>
              <a href="https://wa.me/31626264526">Click Here</a>
              Anass El Quamari
            </p>
            <small>Free Delivery Available for Den Haag and Rotterdam</small>
          </div>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
