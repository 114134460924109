import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

// import SignIn from '../pages/SignIn';
import OutOfOrder from '../pages/OutOfOrder';
// import SignUp from '../pages/SignUp';
// import ForgotPassword from '../pages/ForgotPassword';
// import ResetPassword from '../pages/ResetPassword';

// import Profile from '../pages/Profile';
// import Dashboard from '../pages/Dashboard';
// import Checkout from '../pages/Checkout';
// import AdminOrders from '../pages/AdminOrders';
// import AdminUsers from '../pages/AdminUsers';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={OutOfOrder} />
    {/* <Route path="/signup" exact component={SignUp} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/reset-password" exact component={ResetPassword} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/checkout/:order_id" component={Checkout} isPrivate />
    <Route path="/checkout" component={Checkout} isPrivate />
    <Route path="/admin/orders" component={AdminOrders} isPrivate />
    <Route path="/admin/users" component={AdminUsers} isPrivate /> */}
  </Switch>
);

export default Routes;
