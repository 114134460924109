import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
// import { Product } from '../pages/Dashboard/styles';

import { useAuth } from './auth';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: boolean;
  category_id: string;
  quantity: number;
}

interface ICartContext {
  products: IProduct[];
  addToCart(item: IProduct): void;
  increment(id: string): void;
  decrement(id: string): void;
}

const CartContext = createContext<ICartContext | null>(null);

const CartProvider: React.FC = ({ children }) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const loadedProducts = localStorage.getItem(
        `@Vispunt:${user.id}:cart-products`,
      );

      if (loadedProducts) {
        setProducts(JSON.parse(loadedProducts));
      }
    }
  }, [user]);

  const increment = useCallback(
    async (id) => {
      const newProducts = [...products];
      const productIndex = products.findIndex((p) => p.id === id);

      if (productIndex > -1) {
        newProducts[productIndex].quantity += 1;
        setProducts(newProducts);
      }
      localStorage.setItem(
        `@Vispunt:${user.id}:cart-products`,
        JSON.stringify(newProducts),
      );
    },
    [products, user],
  );

  const decrement = useCallback(
    async (id) => {
      const newProducts = [...products];
      const productIndex = products.findIndex((p) => p.id === id);

      if (productIndex > -1) {
        if (newProducts[productIndex].quantity === 1) {
          newProducts.splice(productIndex, 1);
        } else {
          newProducts[productIndex].quantity -= 1;
        }

        setProducts(newProducts);
      }
      localStorage.setItem(
        `@Vispunt:${user.id}:cart-products`,
        JSON.stringify(newProducts),
      );
    },
    [products, user],
  );

  const addToCart = useCallback(
    async (product) => {
      const productIndex = products.find((item) => item.id === product.id);

      // If found a product
      if (productIndex) {
        increment(productIndex.id);
        return;
      }

      // If didn't find a product
      setProducts((oldState) => [...oldState, { ...product, quantity: 1 }]);
      // console.log('user', user);
      localStorage.setItem(
        `@Vispunt:${user.id}:cart-products`,
        JSON.stringify([...products, { ...product, quantity: 1 }]),
      );
    },
    [products, increment, user],
  );

  const value = React.useMemo(
    () => ({ addToCart, increment, decrement, products }),
    [products, addToCart, increment, decrement],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

function useCart(): ICartContext {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error(`UseCart must be used within a CartProvider`);
  }

  return context;
}

export { CartProvider, useCart, useAuth };
